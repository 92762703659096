:root {
  --primary-green: #388438;
  --action-button-text: #fff;
  --primary-text-black: #222;
  --primary-sub-text-grey: #909090;
  --primary-background-grey: #E5E5E5;
  --primary-border-grey: #cecece;
  --secondary-border-grey :#d8d8d8;
  --hover-background-grey: #f2f2f2
}

body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

body{
  background: transparent;
}

table {
  border-collapse: collapse;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

#root {
  display: flex;
  height: 100vh;
  max-width: 100vw;
}

.container-width-setter {
  min-width: 102.4rem;
  overflow: auto;
}

.modal-width-setter {
  min-width: 132rem;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 8px;
}

::-webkit-scrollbar:hover {
  border: 1px solid #B2B2B2;
}

::-webkit-scrollbar-thumb:hover {
  background: #7e7b7b;
}

::placeholder {
  color: #BCBCBC;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #BCBCBC;
}